export const MaterialModuleFlowMapColor = forwardRef(
  ({ color = 0xffffff, blend = '', alphaTest = -1.0 }, ref) => {
    // COLOR
    const _color = useColor(color);

    const { material } = useMaterialModule({
      name: 'MaterialModuleFlowMapColor',
      uniforms: {
        uFlowMap_Color: { value: _color },
        uFlowMap_AlphaTest: { value: alphaTest },
      },
      fragmentShader: {
        setup: /*glsl*/ `
        uniform vec3 uFlowMap_Color;
        uniform float uFlowMap_AlphaTest;
      `,
        main: /*glsl*/ `
        vec4 flow = 1.0 - texture(tFlowMap, screenPosition);
        pc_fragColor.rgb ${blend}= (1.0 - flow.b) * uFlowMap_Color.rgb;
      `,
      },
    });

    useEffect(() => {
      material.uniforms.uFlowMap_Color.value = _color;
    }, [_color]);

    useEffect(() => {
      material.uniforms.uFlowMap_AlphaTest.value = alphaTest;
    }, [alphaTest]);

    useImperativeHandle(
      ref,
      () => ({
        get color() {
          return material.uniforms.uFlowMap_Color.value;
        },
        set color(val) {
          material.uniforms.uFlowMap_Color.value = val;
        },
        get alphaTest() {
          return material.uniforms.uFlowMap_AlphaTest.value;
        },
        set alphaTest(val) {
          material.uniforms.uFlowMap_AlphaTest.value = val;
        },
      }),
      [material]
    );

    return <></>;
  }
);
