import * as critical from './manifests/0-critical';
import * as ice from './manifests/1-ice';

export const urls = {
  ...critical.urls,
  ...ice.urls,
};

const objectFlip = (obj) => {
  const ret = {};
  Object.keys(obj).forEach((key) => {
    ret[obj[key]] = key;
  });
  return ret;
};

export const ids = objectFlip(urls);

export const fonts = {
  ...critical.fonts,
  ...ice.fonts,
};
export const models = {
  ...critical.models,
  ...ice.models,
};
export const textures = {
  ...critical.textures,
  ...ice.textures,
};
export const sounds = {
  ...critical.sounds,
  ...ice.sounds,
};

export const manifests = {
  critical: critical.manifest,
  ice: ice.manifest,
};

export { critical, ice };

export default {
  critical,
  ice,
};
