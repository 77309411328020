import { urls } from '@/config/assets';

export const IceFog = (props) => {
  const { nodes } = useAsset(urls.m_fog);

  return (
    <group
      {...props}
      dispose={null}
    >
      <mesh
        geometry={nodes.mist.geometry}
        position={[8.745, -22.993, -47.634]}
        rotation={[1.068, 0, 0]}
      >
        <GBufferMaterial transparent>
          <MaterialModuleGradient
            color2={0xffffff}
            color1={new Color(0xffffff).lerp(new Color(0x000000), 0.5)}
          />
          <MaterialModuleAlpha alpha={0.3} />
        </GBufferMaterial>
      </mesh>
      <mesh
        geometry={nodes.mist001.geometry}
        position={[8.745, -42.668, -115.092]}
        rotation={[1.068, 0, 0]}
      >
        <GBufferMaterial transparent>
          <MaterialModuleGradient
            color2={0xffffff}
            color1={new Color(0xffffff).lerp(new Color(0x000000), 0.5)}
          />
          <MaterialModuleAlpha alpha={0.3} />
        </GBufferMaterial>
      </mesh>
      <mesh
        geometry={nodes.mist002.geometry}
        position={[8.745, -26.116, -67.934]}
        rotation={[1.068, 0, 0]}
      >
        <GBufferMaterial transparent>
          <MaterialModuleGradient
            color2={0xffffff}
            color1={new Color(0xffffff).lerp(new Color(0x000000), 0.5)}
          />
          <MaterialModuleAlpha alpha={0.3} />
        </GBufferMaterial>
      </mesh>
    </group>
  );
};
