import { urls } from '@/config/assets';
import {
  CylinderGeometry,
  PlaneGeometry,
  RingGeometry,
  SphereGeometry,
} from 'three';

export const Mist = ({ ...props }) => {
  // const refNoiseTexture = useRef(null);

  const [tMist] = useAsset([urls.t_mist]);
  // const { nodes } = useAsset(urls.m_mist);

  const mistGeometry = suspend(async () => {
    // const geometry = nodes.Cube.geometry;
    const geometry = new PlaneGeometry(1, 1);
    // const geometry = new SphereGeometry(1);
    let s = 4;
    geometry.scale(s, s, s);
    return geometry;
  }, ['SceneIceMist-geometry']);

  const mistEmitterGeometry = suspend(async () => {
    // let geo = new PlaneGeometry(100, 10);
    // geo.rotateY(degToRad(-45));
    // let geo = new CylinderGeometry(
    //   60,
    //   60,
    //   2,
    //   16,
    //   1,
    //   true,
    //   degToRad(0),
    //   degToRad(180)
    // );

    let geo = new RingGeometry(
      6, // innerRadius : Float
      50, // outerRadius : Float
      8, // thetaSegments : Integer
      8, // phiSegments : Integer
      degToRad(0), // thetaStart : Float
      degToRad(360) // thetaLength : Float
    );
    geo.rotateX(degToRad(-90));
    geo.rotateY(degToRad(-45));
    return geo;
  }, [`SceneIceMist-Emitter`]);

  // const mistGeometry = suspend(async () => {
  //   let geo = new PlaneGeometry(0.3, 0.3);
  //   return geo;
  // }, [`SceneIceMist-Geo`]);

  const count = 64 * 64 * 1;
  const life = 10;

  return (
    <group position={[1.5, 2, 2]}>
      <ParticleSystem
        _key={'SceneIce-Mist'}
        enabled={true}
        maxParticles={count}
        looping={true}
        prewarm={true}
        lifeTime={life}
        rate={count / life}
        geometry={mistGeometry}
      >
        <EmissionShape
          geometry={mistEmitterGeometry}
          visible={false}
          maxSamples={count}
        >
          <GBufferMaterial>
            <MaterialModuleColor color="red" />
          </GBufferMaterial>
        </EmissionShape>

        <VelocitySetDirection direction={[-0.5, 0, 0.75]} />

        <PositionUtilCamera />
        <PositionAddMouse
          distance={0.5}
          strength={0.07}
        />

        <RotationSetRandom
          speed={0.1}
          axis={[0, 0, 1]}
        />

        <GBufferMaterial
          transparent={true}
          depthWrite={false}
          blending={AdditiveBlending}
        >
          <MaterialModuleWorldPos />
          <MaterialModuleParticle />
          <MaterialModuleColor color={0x000000} />
          <MaterialModuleMap map={tMist} />
          <MaterialModuleBrightness amount={0.14} />
          <MaterialModuleParticleAlphaTaper taper={0.1} />
        </GBufferMaterial>
      </ParticleSystem>
    </group>
  );
};
