import './Home.sass';

export const Home = () => {
  const [isMuted, setIsMuted] = useState(true);

  return (
    <>
      <button
        className="btn btn-mute"
        onClick={() => {
          document.querySelector('.bgm').play();
          document.querySelector('.bgm').muted = !isMuted;
          setIsMuted(!isMuted);
        }}
      >
        {isMuted ? (
          <img src="/assets/images-next/sound-off.webp" />
        ) : (
          <img src="/assets/images-next/sound-on.webp" />
        )}
      </button>

      <div className="bottom-right">
        <a
          className="btn"
          href="https://t.me/hi_is_back"
          target="_blank"
        >
          <img src="/assets/images-next/icon-telegram.webp" />
        </a>
        <a
          className="btn"
          href="https://twitter.com/hi_is_back"
          target="_blank"
        >
          <img src="/assets/images-next/icon-x.webp" />
        </a>
      </div>
    </>
  );
};
