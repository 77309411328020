import { urls } from '@/config/assets';
import { PlaneGeometry } from 'three';

export const WinterSnow = ({ ...props }) => {
  const [tSnow] = useAsset([urls.t_snow]);

  const snowEmitterGeometry = suspend(async () => {
    let geo = new PlaneGeometry(100, 100);
    geo.rotateX(degToRad(90));
    return geo;
  }, [`WinterSnowSprites-Emitter`]);

  const snowGeometry = suspend(async () => {
    let geo = new PlaneGeometry(0.2, 0.2);
    return geo;
  }, [`WinterSnowSprites-Geo`]);

  return (
    <group position={[0, 30, 0]}>
      <ParticleSystem
        _key={'SceneIce-SnowSprites'}
        enabled={true}
        maxParticles={64 * 64 * 1}
        looping={true}
        prewarm={true}
        lifeTime={30}
        rate={(64 * 64 * 1) / 30}
        geometry={snowGeometry}
      >
        <EmissionShape
          geometry={snowEmitterGeometry}
          visible={false}
        >
          <GBufferMaterial wireframe>
            <MaterialModuleColor color="red" />
          </GBufferMaterial>
        </EmissionShape>

        <VelocitySetDirection direction={[0, -1, 0]} />
        <VelocityAddOverTime direction={[-0.1, 0, 0.1]} />
        <RotationSetRandom speed={[0.3, 3]} />
        {/* <PositionGroundLimit y={0.4} /> */}
        {/* <RotationGroundLimit y={0.4} /> */}

        <PositionUtilCamera />
        <PositionAddMouse
          distance={0.5}
          strength={0.05}
        />

        <ParticleSystemSpriteMaterial
          map={tSnow}
          cols={8}
          alphaTest={0.5}
          fallOff={[10, 1, 0.3]}
          color={0xffffff}
        />
      </ParticleSystem>

      {/* 
      <ParticleSystem
        _key={'SceneOverviewWinter-SnowPoints'}
        enabled={true}
        maxParticles={32 * 32 * 0.25}
        looping={true}
        prewarm={true}
        lifeTime={20}
        rate={(32 * 32 * 0.25) / 20}
      >
        <EmissionPlane
          width={20}
          height={20}
          plane="xz"
        />

        <VelocitySetDirection direction={[0, -1, 0]} />
        <VelocityAddOverTime direction={[-0.1, 0, 0]} />
        <RotationSetRandom speed={[0.3, 3]} />
        <PositionGroundLimit y={0.4} />
        <RotationGroundLimit y={0.4} />

        <PositionUtilCamera />
        <PositionAddMouse
          distance={0.5}
          strength={0.05}
        />

        <ParticleAmbianceMaterial
          size={0.3}
          alpha={1}
        />
      </ParticleSystem> */}
    </group>
  );
};
