export const IceSprite = ({ id, ...props }) => {
  const texture = useAsset(urls[`t_${id}`]);

  return (
    <mesh scale={[1, -1, 1]}>
      <planeGeometry args={[1, 1]} />
      <GBufferMaterial>
        <MaterialModuleNormal />
        <MaterialModuleSpriteAnimated
          map={texture}
          rows={4}
          cols={4}
          frames={12}
          fps={12}
        />
        <MaterialModuleAlphaTest />
        <MaterialModuleFlowMap />
        <MaterialModuleFlowMapColor blend="+" />
      </GBufferMaterial>
    </mesh>
  );
};
