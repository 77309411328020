export const ASSET_TYPES = {
  OTHER: 'OTHER',

  JSON: 'JSON',
  VIDEO: 'VIDEO',
  FONT: 'FONT',
  AUDIO: 'AUDIO',
  IMAGE: 'IMAGE',

  GLTF: 'GLTF',
  TEXTURE: 'TEXTURE',
  TEXTURE_KTX2: 'TEXTURE_KTX2',
  TEXTURE_BASIS: 'TEXTURE_BASIS',
  TEXTURE_HDR: 'TEXTURE_HDR',
};
