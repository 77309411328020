import { createContext, useContext, useEffect } from 'react';
import { useAppStore } from '@/stores/app';
import {
  scenes,
  offsetMap,
  totalLength,
  scrollLengthPx,
} from '@/components/webgl/scenes/config';
import Lenis from 'lenis';
import { getUrlBoolean } from '@/helpers/UrlParam';
import Env from '@/helpers/Env';

export const ScrollContext = createContext();

const maxVelocity = 20; // 10px per sec
const snapWaitTime = 200;

const noLimit = getUrlBoolean('noLimit');
const noSnap = getUrlBoolean('noSnap');

export const ScrollProvider = ({ children, start = 0 }) => {
  // SCROLL STATE
  const scroll = useRef({
    current: start,
    isPaused: false,
    velocity: 0,
    direction: 0,
    isAnimating: false,
    isLoop: false,
  });

  const isScrolling = useRef(false);
  const snapTimeout = useRef();

  // LENIS
  const lenis = useMemo(
    () =>
      new Lenis({
        normalizeWheel: true,
        infinite: true,
        syncTouch: Env.mobile,
      })
  );

  useEffect(() => {
    lenis.on('scroll', checkSnap);
    window.lenis = lenis;
    return () => {
      lenis.destroy();
    };
  }, [lenis]);

  // LIMIT
  const checkLimit = (evt) => {
    if (noLimit) return;

    const dir = scroll.current.direction;

    const currS = scroll.current.current % totalLength;
    const currSceneIdx = offsetMap.findIndex((o) => o > currS) - 1;
    const currP = currS - offsetMap[currSceneIdx];

    const limits = scenes[currSceneIdx]?.scroll?.limits || [];
    const currLimit = limits.find((l) => l + 0.1 >= currP);

    let limited = false;

    if (currLimit != undefined && dir == 1) {
      limited = currP >= currLimit;

      if (limited) {
        scroll.current.current = Math.min(
          scroll.current.current,
          offsetMap[currSceneIdx] + currLimit
        );
      }
    }

    return limited;
  };

  // SNAP
  const checkSnap = (evt, overrideSnap = false) => {
    if (noSnap) return;
    if (scroll.current.isPaused) return;
    if (scroll.current.isAnimating) return;

    const dir = scroll.current.direction;

    const currS = scroll.current.current;
    const currSceneIdx = offsetMap.findIndex((o) => o > currS) - 1;
    const nextSceneIdx = currSceneIdx + 1;
    const transition = scenes[currSceneIdx]?.transition || {};
    const p = currS - offsetMap[currSceneIdx];

    const shouldSnap = overrideSnap || transition.snap != false;

    // Transition to next scene
    if (dir == 1 && p > transition.start && shouldSnap) {
      let to = offsetMap[nextSceneIdx];
      // const duration = typeof transition.snap == 'number' ? transition.snap : 1;
      scrollTo({ to, duration: 1, ease: 'power1.inOut' });
      return;
    }

    // Transition back to previous scene
    if (dir == -1 && p > transition.start && p < transition.end && shouldSnap) {
      let to = offsetMap[currSceneIdx] + transition.start;
      scrollTo({ to, duration: 1, ease: 'power1.inOut' });
      return;
    }
  };

  /**
   * Clears snap timeout
   */
  function handleSnapTimeout() {
    clearTimeout(snapTimeout.current);

    snapTimeout.current = setTimeout(() => {
      checkSnap(null, true);
    }, snapWaitTime);
  }

  const update = useCallback((time, delta, frame) => {
    lenis.raf(time * 1000);

    if (lenis.isScrolling && !isScrolling.current) {
      // initiaite timeout
      isScrolling.current = true;

      clearTimeout(snapTimeout.current);
    } else if (!lenis.isScrolling && isScrolling.current) {
      // clear
      isScrolling.current = false;

      handleSnapTimeout();
    }

    if (scroll.current.current < 0) {
      scroll.current.current = 0;
    }

    const velocity =
      Math.min(Math.abs(lenis.velocity), maxVelocity) *
      Math.sign(lenis.velocity);

    scroll.current.velocity = velocity;
    scroll.current.direction = lenis.direction;

    if (scroll.current.isPaused) return;
    if (!scroll.current.isAnimating) {
      if (checkLimit()) return;
    }
    scroll.current.current += velocity / scrollLengthPx;

    if (scroll.current.current <= 0) {
      scroll.current.current = 0;
    }

    scroll.current.isLoop = scroll.current.current >= totalLength;
  });

  // useFrame();
  useEffect(() => {
    gsap.ticker.add(update);
    return () => {
      gsap.ticker.remove(update);
    };
  }, [update]);

  // METHODS
  const pause = useCallback(() => {
    scroll.current.isPaused = true;
    // lenis.stop();
  }, [lenis]);

  const unpause = useCallback(() => {
    scroll.current.isPaused = false;
    // lenis.start();
  }, [lenis]);

  const scrollTo = useCallback(
    async ({ to = 0, duration = 1, ease, lock = false }) => {
      scroll.current.isAnimating = true;
      if (lock) pause();

      await gsap.to(scroll.current, { current: to, duration, ease }).then();

      scroll.current.isAnimating = false;
      if (lock) unpause();
    },
    [lenis]
  );

  // MENU
  const showMenu = useAppStore((state) => state.showMenu);
  const showSubscribe = useAppStore((state) => state.showSubscribe);
  useEffect(() => {
    if (showMenu || showSubscribe) {
      pause();
    } else {
      unpause();
    }
  }, [showMenu, showSubscribe]);

  const ctx = useMemo(
    () => ({ scroll, pause, unpause, scrollTo }),
    [scroll, pause, unpause, scrollTo]
  );

  return (
    <ScrollContext.Provider value={ctx}>
      <div
        className="scroll-spacer"
        style={{ height: `${totalLength * scrollLengthPx}px` }}
      />

      {children}
    </ScrollContext.Provider>
  );
};

export const useScroller = () => useContext(ScrollContext);
