import { getUrlBoolean } from '@/helpers/UrlParam';
import { StatsGl } from '@react-three/drei';

import { useThree } from '@react-three/fiber';
import usePerformance from './hooks/usePerformance';

import { Loading } from '../components/_misc/Loading';
import { three, hud } from '@/tunnels';

const stats = getUrlBoolean('stats', false);
const perf = getUrlBoolean('perf', false);

export const Nose = () => {
  const gl = useThree((state) => state.gl);

  // COLOR SPACE
  useEffect(() => {
    gl.outputColorSpace = LinearSRGBColorSpace;
  }, [gl]);

  // PERFORMANCE
  const { update } = usePerformance(gl, perf);
  useFrame(update);

  const { completed } = useAssetProgress();

  return (
    <>
      <MouseTracker />

      <FsTriangleCamera />

      <Suspense fallback={<Loading />}>
        <PreloadAssets />
        {completed && <NoseScenes />}
        <three.Out />
        <NoseHud />
      </Suspense>

      <Render />

      {stats && <StatsGl />}
      <GsapSync />
    </>
  );
};
