import { three, hud } from '@/tunnels';
import { Trail, Hud, OrthographicCamera } from '@react-three/drei';
import { getUrlString, getUrlBoolean } from '@/helpers/UrlParam';
import { FollowMouse2 } from '@/webgl/controls/FollowMouse2';
import Env from '@/helpers/Env';

export const NoseHud = () => {
  const grain = getUrlBoolean('grain', true);

  const viewport = useThree((s) => s.viewport);

  return (
    <>
      <Hud renderPriority={20000}>
        <OrthographicCamera
          makeDefault
          position={[0, 0, 10]}
        />

        {/* <NoseLogo /> */}

        <hud.Out />

        {/* {!Env.mobile && (
          <Trail
            width={0.02 * viewport.dpr} // Width of the line
            color={colors.beige} // Color of the line
            length={0.4} // Length of the line
            decay={0.05} // How fast the line fades away
            local={false} // Wether to use the target's world or local positions
            stride={0} // Min distance between previous and current point
            interval={1} // Number of frames to wait before next calculation
          >
            <FollowMouse2 smoothTime={0.1}></FollowMouse2>
          </Trail>
        )} */}

        {/* {grain && <FilmGrain />} */}
      </Hud>
    </>
  );
};
