import { degToRad } from 'three/src/math/MathUtils.js';
import { damp, dampC } from 'maath/easing';
import { IceLightingContext } from './context';
import { getUrlFloat, getUrlBoolean } from '@/helpers/UrlParam';

const day = {
  lighting: { ambient: 0.33 },
  ambient: { color: 0xffffff },
  sun: {
    color: new Color(0xffffff).lerp(new Color(0xffffff), 0),
    alpha: 0.3,
  },
  text: {
    gradient: {
      color1: new Color(0x00f1ff).lerp(new Color(0x000000), 0.1),
      color2: new Color(0x00f1ff).lerp(new Color(0x000000), 0.2),
      color3: new Color(0x00f1ff).lerp(new Color(0x000000), 0.3),
    },
    flow: {
      color: new Color(0x00f1ff).lerp(new Color(0x000000), 0.5),
    },
  },
  icecube: {
    brightness: 1,
    flow: {
      color: new Color(0x81c7ff).lerp(new Color(0x000000), 0.6),
    },
  },
};
const sunset = {
  lighting: { ambient: 0.35 },
  ambient: { color: new Color(0xfff4ee).lerp(new Color(0xffffff), -3) },
  sun: {
    color: new Color(0xfff7ab).lerp(new Color(0xffffff), -3),
    alpha: 0.6,
  },
  text: {
    gradient: {
      color1: new Color(0x00f1ff).lerp(new Color(0x000000), 0.1 * -0.25),
      color2: new Color(0x00f1ff).lerp(new Color(0x000000), 0.2 * -0.25),
      color3: new Color(0x00f1ff).lerp(new Color(0x000000), 0.3 * -0.25),
    },
    flow: {
      color: new Color(0x00f1ff).lerp(new Color(0x000000), 0.5 * -0.5),
    },
  },
  icecube: {
    brightness: 1.25,
    flow: {
      color: new Color(0x81c7ff).lerp(new Color(0x000000), 0.6),
    },
  },
};
const night = {
  lighting: { ambient: 0.25 },
  ambient: { color: new Color(0x2963a4).lerp(new Color(0xffffff), -0.7) },
  sun: { alpha: 0.1 },
  text: {
    gradient: {
      // color1: new Color(0x00f1ff).lerp(new Color(0x000000), 0.1 * -2.5),
      // color2: new Color(0x00f1ff).lerp(new Color(0x000000), 0.2 * -2.5),
      // color3: new Color(0x00f1ff).lerp(new Color(0x000000), 0.3 * -2.5),
      color1: new Color(0xebd762).lerp(new Color(0x000000), 0.1 * -10),
      color2: new Color(0xebd762).lerp(new Color(0x000000), 0.2 * -10),
      color3: new Color(0xebd762).lerp(new Color(0x000000), 0.3 * -10),
    },
    flow: {
      color: new Color(0xffffff).lerp(new Color(0x000000), -1),
    },
  },
  icecube: {
    brightness: 2,
    flow: {
      color: new Color(0x81c7ff).lerp(new Color(0x000000), 0.6 * -3),
    },
  },
};

const lightConfigs = [
  {
    //
    id: 'early',
    to: 6,
    ...night,
  },
  {
    id: 'sunrise',
    to: 7,
    ...sunset,
  },
  {
    //
    id: 'day',
    to: 18,
    ...day,
  },
  {
    id: 'sunset',
    to: 19,
    ...sunset,
  },
  {
    id: 'night',
    to: 24,
    ...night,
  },
];

const speedTime = getUrlFloat('speedTime', null);

export const IceLighting = ({ children }) => {
  // *****************************************************************
  //
  // TIME OF DAY
  //
  // *****************************************************************
  const refLighting = useRef(null);
  const refLightSunWrap = useRef(null);
  const refLightSun = useRef(null);
  const refLightAmbient = useRef(null);

  const time = useRef(0);
  const smoothTime = 1;

  const [lightConfig, setLightConfig] = useState();

  useFrame((state, delta) => {
    if (speedTime == null || isNaN(speedTime)) return;
    time.current += delta * speedTime;
    time.current = time.current % 24;
    let config = lightConfigs.find((item) => {
      return time.current < item.to;
    });
    if (lightConfig?.id != config?.id) setLightConfig(config);

    // AMBIENT
    damp(
      //
      refLighting.current,
      'ambient',
      config.lighting.ambient,
      smoothTime,
      delta
    );
    dampC(
      //
      refLightAmbient.current.color,
      config.ambient.color,
      smoothTime,
      delta
    );

    // SUN

    refLightSunWrap.current.rotation.z = degToRad(
      (time.current / 24) * 360 + 180
    );
    damp(
      //
      refLightSun.current,
      'alpha',
      config.sun.alpha || 0,
      smoothTime,
      delta
    );
    dampC(
      //
      refLightSun.current.color,
      config.sun.color,
      smoothTime,
      delta
    );
  });

  const setTod = (t) => {
    let config = lightConfigs.find((item) => {
      return t < item.to;
    });
    setLightConfig(config);

    // AMBIENT
    refLighting.current.ambient = config.lighting.ambient;
    refLightAmbient.current.color.set(config.ambient.color);

    // SUN
    refLightSunWrap.current.rotation.z = degToRad(
      (time.current / 24) * 360 + 180
    );
    refLightSun.current.color.set(config.sun.color);
    refLightSun.current.alpha = config.sun.alpha || 0;
  };

  const update = () => {
    let date = new Date();
    time.current = date.getHours() + date.getMinutes() / 60;
    if (getUrlFloat('time', null) != null) {
      time.current = getUrlFloat('time');
    }
    // console.log(
    //   date,
    //   date.getHours(),
    //   date.getMinutes(),
    //   time.current,
    //   getUrlFloat('time', null)
    // );
    setTod(time.current);
  };

  useEffect(() => {
    update();
    if (speedTime != null) return;
    let interval = setInterval(update, 1000); // TODO: Real time
    return () => {
      clearInterval(interval);
    };
  }, []);

  const ctx = useMemo(() => {
    return {
      lightConfig,
    };
  }, [lightConfig]);

  return (
    <>
      <DeferredLighting
        ref={refLighting}
        ambient={0.35}
      />

      <PointLightDeferred
        ref={refLightAmbient}
        distance={0}
        decay={0}
        intensity={1}
        alpha={1}
      />

      <group
        ref={refLightSunWrap}
        position={[0, 0, 0]}
        rotation={[degToRad(0), degToRad(0), 0]}
      >
        <PointLightDeferred
          ref={refLightSun}
          position={[0, 60, 0]}
          distance={0}
          decay={0.01}
          intensity={1}
          alpha={1}
          color={'white'}
        />
        <PointLightDeferred
          position={[0, -60, 0]}
          distance={0}
          decay={0.01}
          intensity={1}
          alpha={1}
          color={0x333333}
        />
      </group>

      <IceLightingContext.Provider value={ctx}>
        {children}
      </IceLightingContext.Provider>
    </>
  );
};
