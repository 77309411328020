import { urls } from '@/config/assets';
import { PerspectiveCamera, OrbitControls } from '@react-three/drei';
// import { OrbitControls } from 'three-stdlib';
import { useGLTF as useGLTFDrei } from '@react-three/drei';
import { damp, dampC } from 'maath/easing';

export function IceBg({ postOutline, ...props }) {
  const { scene, nodes, materials } = useAsset(urls.m_bg);
  const t_bg1 = useAsset(urls.t_bg1);
  const t_bg2 = useAsset(urls.t_bg2);

  return (
    <group
      {...props}
      dispose={null}
    >
      <mesh
        geometry={nodes.Plane.geometry}
        material={materials['Material.008']}
        position={[-66.089, -22.481, -74.639]}
        rotation={[1.119, 0, 0]}
        scale={[52.869, 29.459, 29.459]}
      >
        <GBufferMaterial
          side={DoubleSide}
          transparent
        >
          <MaterialModuleNormal />
          <MaterialModuleMap map={t_bg1} />
          <MaterialModuleAlphaTest />
        </GBufferMaterial>
      </mesh>
      <mesh
        geometry={nodes.Plane004.geometry}
        material={materials['Material.009']}
        position={[-38.862, -48.639, -119.64]}
        rotation={[1.119, 0, 0]}
        scale={[87.772, 48.907, 48.907]}
      >
        <GBufferMaterial
          side={DoubleSide}
          transparent
        >
          <MaterialModuleNormal />
          <MaterialModuleMap map={t_bg2} />
          <MaterialModuleAlphaTest />
        </GBufferMaterial>
      </mesh>
    </group>
  );
}
