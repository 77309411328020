import { urls } from '@/config/assets';
import { PerspectiveCamera, OrbitControls } from '@react-three/drei';
// import { OrbitControls } from 'three-stdlib';
import { useGLTF as useGLTFDrei } from '@react-three/drei';

export function IceLines({ postOutline, ...props }) {
  const { nodes, materials } = useAsset(urls.m_lines);

  return (
    <group
      {...props}
      dispose={null}
    >
      <mesh
        name="darkerLine"
        geometry={nodes.darkerLine.geometry}
      >
        <GBufferMaterial>
          <MaterialModuleColor color={materials.LineDark.emissive} />
          <MaterialModuleNormal />
        </GBufferMaterial>
      </mesh>
      <mesh
        name="LighterLine"
        geometry={nodes.LighterLine.geometry}
      >
        <GBufferMaterial>
          <MaterialModuleColor color={materials.LineLight.emissive} />
          <MaterialModuleNormal />
        </GBufferMaterial>
      </mesh>

      <mesh geometry={nodes.foot.geometry}>
        <GBufferMaterial>
          <MaterialModuleColor color={materials.foot.emissive} />
          <MaterialModuleNormal />
        </GBufferMaterial>
      </mesh>

      <group
        _name="SnowMound"
        name="Cube003"
        position={[7.203, 0.675, 6.712]}
        rotation={[0, -0.403, 0]}
      >
        <mesh
          _name="SnowMound_Front"
          name="Cube"
          geometry={nodes.Cube.geometry}
        >
          <GBufferMaterial>
            <MaterialModuleColor color={materials.mountain.emissive} />
            <MaterialModuleNormal />
          </GBufferMaterial>
        </mesh>
        {!postOutline && (
          <mesh
            _name="SnowMound_OL"
            name="Cube_1"
            geometry={nodes.Cube_1.geometry}
          >
            <GBufferMaterial>
              <MaterialModuleColor color={materials.GreenOL.emissive} />
              <MaterialModuleNormal />
            </GBufferMaterial>
          </mesh>
        )}
      </group>
    </group>
  );
}
