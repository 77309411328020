export const MaterialModuleColor = memo(
  forwardRef(({ color, opacity = 1, blend = '' }, ref) => {
    // COLOR
    const _color = useColor(color);

    const { material } = useMaterialModule({
      name: 'MaterialModuleColor',
      uniforms: {
        uColor: { value: _color },
        uColor_Alpha: { value: opacity },
      },
      fragmentShader: {
        setup: /*glsl*/ `
          uniform vec3 uColor;
          uniform float uColor_Alpha;
        `,
        main: /*glsl*/ `
          pc_fragColor ${blend}= vec4(uColor, uColor_Alpha);
        `,
      },
    });

    useEffect(() => {
      material.uniforms.uColor.value = _color;
    }, [_color]);

    useEffect(() => {
      material.uniforms.uColor_Alpha.value = opacity;
    }, [opacity]);

    useImperativeHandle(
      ref,
      () => ({
        _color,
        get color() {
          return material.uniforms['uColor'].value;
        },
        set color(val) {
          material.uniforms['uColor'].value = val;
        },

        get alpha() {
          return material.uniforms['uColor_Alpha'].value;
        },
        set alpha(val) {
          material.uniforms['uColor_Alpha'].value = val;
        },
      }),
      [material]
    );

    return <></>;
  })
);
