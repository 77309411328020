import { urls } from '@/config/assets';
import { Box, PerspectiveCamera, OrbitControls } from '@react-three/drei';
import { folder, useControls } from 'leva';
import { degToRad } from 'three/src/math/MathUtils.js';
import { randomIntRange } from '@/helpers/MathUtils.js';
import Env from '@/helpers/Env';
import { getUrlBoolean, getUrlString } from '@/helpers/UrlParam';

const weatherTypes = ['snow', 'rain'];
const weatherIdx = randomIntRange(0, weatherTypes.length - 1);
const weather = getUrlString('weather', weatherTypes[weatherIdx]);

export function SceneIce({ ...props }) {
  const hover = useControls('SceneIce', {
    hover: folder({
      rot: [0.15, 0.15, 0],
      smoothTime: 0.2,
    }),
  });

  const postOutline = useMemo(() => getUrlBoolean('postOutline', false));

  useAspectRatioCameraFov(19.231, 16 / 9);

  return (
    <>
      <DeferredDebug />
      {/* <DeferredAtmosphere
        fogColor={'#A87980'}
        fogColor2={'#ae72af'}
        _key="SceneIce-Atmosphere"
        heightFactor={0.003}
        fogDensity={0.01}
      /> */}
      {postOutline && <DeferredOutline normalThreshold={1.0} />}
      {/* <Plane
        args={[1000, 1000]}
        position={[0, 0, -120]}
      >
        <GBufferMaterial
          side={DoubleSide}
          transparent
        >
          <MaterialModuleNormal />
          <MaterialModuleColor color="0x5adefd" />
        </GBufferMaterial>
      </Plane> */}

      <BackgroundColor color={'white'} />
      <Sky
        color1={0x5adefd}
        color2={0x5adefd}
        skyDepth={0}
      />

      <HoverControls {...hover}>
        <group rotation={[degToRad(-30), 0, 0]}>
          <ZoomStepControls
            steps={[Env.mobile ? -25 : -28, 0, Env.mobile ? 170 : 110]}
            snap={true}
          >
            <PerspectiveCamera
              makeDefault
              position={[0, 0, Env.mobile ? 50 : 50]}
              near={1}
              far={2000}
              fov={19.231}
              // position={[-0.5, 0.5, 7]}
              // position={[10, -2, 35]}
              // position={[0, 0, 187]}
              // far={1000}
            />
          </ZoomStepControls>
        </group>
      </HoverControls>
      {/* <OrbitControls /> */}

      <IceLighting>
        <IceBg postOutline={postOutline} />
        <IceFog />
        <IceGround postOutline={postOutline} />
        <IceCliffs postOutline={postOutline} />
        <IceLines postOutline={postOutline} />
        <IceTrees postOutline={postOutline} />
        <IceCube postOutline={postOutline} />
        <IceChara postOutline={postOutline} />

        <Suspense>
          <Mist />
        </Suspense>

        <Suspense>
          {weather == 'snow' && <WinterSnow postOutline={postOutline} />}
          {weather == 'rain' && <Rain postOutline={postOutline} />}
        </Suspense>

        {/* <Suspense>
          <WinterSnow postOutline={postOutline} />
        </Suspense>
        <Suspense>
          <Rain postOutline={postOutline} />
        </Suspense> */}
      </IceLighting>
    </>
  );
}
