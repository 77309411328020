import { urls } from '@/config/assets';
import { PerspectiveCamera, OrbitControls } from '@react-three/drei';
// import { OrbitControls } from 'three-stdlib';
import { useGLTF as useGLTFDrei } from '@react-three/drei';
import { damp, dampC } from 'maath/easing';

export function IceCliffs({ postOutline, ...props }) {
  const { scene, nodes, materials } = useAsset(urls.m_cliffs);

  return (
    <group
      {...props}
      dispose={null}
    >
      <group
        position={[36.528, -1.994, -12.759]}
        rotation={[Math.PI, -0.795, Math.PI]}
      >
        <mesh
          geometry={nodes.Landscape004_1.geometry}
          material={materials.MountBottom}
        >
          <GBufferMaterial>
            <MaterialModuleNormal />
            <MaterialModuleColor color={materials.MountBottom.emissive} />
          </GBufferMaterial>
        </mesh>
        <mesh
          geometry={nodes.Landscape004_2.geometry}
          material={materials.mountTop}
        >
          <GBufferMaterial>
            <MaterialModuleNormal />
            <MaterialModuleColor color={materials.mountTop.emissive} />
          </GBufferMaterial>
        </mesh>
        <mesh
          geometry={nodes.Landscape004_3.geometry}
          material={materials.MountLineinner}
        >
          <GBufferMaterial>
            <MaterialModuleNormal />
            <MaterialModuleColor color={materials.MountLineinner.emissive} />
          </GBufferMaterial>
        </mesh>
        <mesh
          geometry={nodes.Landscape004_4.geometry}
          material={materials.MountLine}
        >
          <GBufferMaterial>
            <MaterialModuleNormal />
            <MaterialModuleColor color={materials.MountLine.emissive} />
          </GBufferMaterial>
        </mesh>
      </group>
      <group
        position={[-9.588, -2.45, 25.44]}
        rotation={[Math.PI, -0.432, Math.PI]}
      >
        <mesh
          geometry={nodes.Landscape021.geometry}
          material={materials.MountBottom}
        >
          <GBufferMaterial>
            <MaterialModuleNormal />
            <MaterialModuleColor color={materials.MountBottom.emissive} />
          </GBufferMaterial>
        </mesh>
        <mesh
          geometry={nodes.Landscape021_1.geometry}
          material={materials.mountTop}
        >
          <GBufferMaterial>
            <MaterialModuleNormal />
            <MaterialModuleColor color={materials.mountTop.emissive} />
          </GBufferMaterial>
        </mesh>
        <mesh
          geometry={nodes.Landscape021_2.geometry}
          material={materials.MountLineinner}
        >
          <GBufferMaterial>
            <MaterialModuleNormal />
            <MaterialModuleColor color={materials.MountLineinner.emissive} />
          </GBufferMaterial>
        </mesh>
        <mesh
          geometry={nodes.Landscape021_3.geometry}
          material={materials.MountLine}
        >
          <GBufferMaterial>
            <MaterialModuleNormal />
            <MaterialModuleColor color={materials.MountLine.emissive} />
          </GBufferMaterial>
        </mesh>
      </group>
    </group>
  );
}
