import { ScrollProvider } from '@/contexts/scroll';
import {
  OrthographicCamera,
  RenderTexture as RenderTextureDrei,
  Hud,
} from '@react-three/drei';
import {
  EffectComposer,
  Bloom,
  Noise,
  Vignette,
} from '@react-three/postprocessing';
import { scenes, totalLength } from '@/components/webgl/scenes/config';

import { getUrlFloat, getUrlBoolean } from '@/helpers/UrlParam';
import { ui, three, hud } from '@/tunnels';
import { useEffect } from 'react';

export function NoseScenes({ ...props }) {
  const camera = useThree((s) => s.camera);
  const _scene = useThree((s) => s.camera);
  const gl = useThree((s) => s.gl);

  useEffect(() => {
    gl.compile(_scene, camera);
  }, []);

  return (
    <>
      <FlowMap
        falloff={0.1}
        alpha={0.5}
        dissipation={0.94}
      />

      {/* <FXAA enabled={getUrlBoolean('fxaa', true)}> */}
      <mesh geometry={fsTriangle}>
        <meshBasicMaterial>
          <RenderTextureDeferred
            idx={0}
            // fboProps={{ count: 1 }}
            renderPriority={100}
          >
            <SceneIce />
          </RenderTextureDeferred>
        </meshBasicMaterial>
      </mesh>
      {/* </FXAA> */}
    </>
  );
}
