import { urls } from '@/config/assets';
import { PlaneGeometry } from 'three';

export const Rain = ({ ...props }) => {
  const [tRain] = useAsset([urls.t_rain]);

  const refSystem = useRef(null);

  const rainEmitterGeometry = suspend(async () => {
    let geo = new PlaneGeometry(100, 100);
    geo.rotateX(degToRad(90));
    return geo;
  }, [`WinterRainSprites-Emitter`]);

  const rainGeometry = suspend(async () => {
    let geo = new PlaneGeometry(0.3, 0.3);
    return geo;
  }, [`WinterRainSprites-Geo`]);

  // useEffect(()=> {
  //   refSystem.
  // }, [])

  return (
    <group position={[0, 30, 0]}>
      <ParticleSystem
        ref={refSystem}
        _key={'SceneIce-RainSprites'}
        enabled={true}
        maxParticles={256 * 256 * 1}
        looping={true}
        prewarm={true}
        lifeTime={30}
        rate={(256 * 256 * 1) / 30}
        geometry={rainGeometry}
      >
        <EmissionShape
          geometry={rainEmitterGeometry}
          visible={false}
        >
          <GBufferMaterial wireframe>
            <MaterialModuleColor color="red" />
          </GBufferMaterial>
        </EmissionShape>

        <VelocitySetDirection direction={[0, -9, 0]} />
        <VelocityAddOverTime direction={[0, -1, 0]} />

        {/* <RotationSetAlignToDirection /> */}
        {/* <RotationSetBillboard /> */}
        {/* 
        <PositionUtilCamera />
        <PositionAddMouse
          distance={0.5}
          strength={0.05}
        /> */}

        <ParticleSystemSpriteMaterial
          map={tRain}
          cols={7}
          alphaTest={0.5}
          fallOff={[10, 1, 0.3]}
          color={0xdddddd}
        />
      </ParticleSystem>
    </group>
  );
};
