import { ASSET_TYPES } from '@/services/AssetService/config';
import Env from '@/helpers/Env';

// **********************************************************************
// URLS
// **********************************************************************
export const fonts = {};

export const models = {
  m_cube: `/assets/models-o/1_ice/cube3.glb`,
  m_trees: `/assets/models-o/1_ice/trees3.glb`,
  m_lines: `/assets/models-o/1_ice/lines3.glb`,
  m_ground: `/assets/models-o/1_ice/ground5.glb`,
  m_cliffs: `/assets/models-o/1_ice/cliffs3.glb`,
  m_bg: `/assets/models-o/1_ice/bg5.glb`,

  m_fog: `/assets/models-o/1_ice/fog.glb`,
};

export const textures = {
  t_bg1: `/assets/textures-o/1_ice/bg/bg1.ktx2`,
  t_bg2: `/assets/textures-o/1_ice/bg/bg2.ktx2`,

  t_snow: `/assets/textures-o/1_ice/particles/snow.ktx2`,
  t_rain: `/assets/textures-o/1_ice/particles/rain2.ktx2`,
  // t_mist: `/assets/textures-o/1_ice/particles/mist.ktx2`,

  t_cube: `/assets/textures-o/1_ice/icecube/dark.ktx2`,

  t_pop: `/assets/textures-o/1_ice/charas/pop.ktx2`,
  t_mew: `/assets/textures-o/1_ice/charas/mew.ktx2`,
  t_wif: `/assets/textures-o/1_ice/charas/wif.ktx2`,
  t_shib: `/assets/textures-o/1_ice/charas/shib.ktx2`,
  t_pepe: `/assets/textures-o/1_ice/charas/pepe.ktx2`,
  t_bome: `/assets/textures-o/1_ice/charas/bome.ktx2`,
  t_slerf: `/assets/textures-o/1_ice/charas/slerf.ktx2`,
  t_doge: `/assets/textures-o/1_ice/charas/doge.ktx2`,

  // MIST
  t_mist: `/assets/textures-o/mist/particle.ktx2`,
  // t_mist_mask: `/assets/textures-o/mist/T_NoiseMask_03.ktx2`,
};

export const sounds = {};

export const urls = {
  ...fonts,
  ...models,
  ...textures,
  ...sounds,
};

// **********************************************************************
// MANIFEST
// **********************************************************************
export const manifest = {
  id: 'ice',
  items: [
    ...Object.entries(fonts).map(([id, url]) => ({ id, url })),
    ...Object.entries(models).map(([id, url]) => ({ id, url })),
    ...Object.entries(textures).map(([id, url]) => ({ id, url })),
    ...Object.entries(sounds).map(([id, url]) => ({ id, url })),
  ],
};
