import { urls } from '@/config/assets';
import { PerspectiveCamera, OrbitControls } from '@react-three/drei';
// import { OrbitControls } from 'three-stdlib';
import { useGLTF as useGLTFDrei } from '@react-three/drei';

export function IceTrees({ postOutline, ...props }) {
  const { nodes, materials } = useAsset(urls.m_trees);

  return (
    <group
      {...props}
      dispose={null}
    >
      <group
        position={[-6.28, 0, -1.716]}
        rotation={[0, -0.093, 0]}
        scale={0.799}
      >
        <mesh geometry={nodes.Cube121.geometry}>
          <GBufferMaterial>
            <MaterialModuleColor color={materials.treeTopC.emissive} />
            <MaterialModuleNormal />
            <MaterialModuleFlowMap />
            <MaterialModuleWind />
          </GBufferMaterial>
        </mesh>
        <mesh geometry={nodes.Cube121_1.geometry}>
          <GBufferMaterial>
            <MaterialModuleColor color={materials.trunkColor.emissive} />
            <MaterialModuleNormal />
            <MaterialModuleFlowMap />
            <MaterialModuleWind />
          </GBufferMaterial>
        </mesh>
        <mesh geometry={nodes.Cube121_2.geometry}>
          <GBufferMaterial>
            <MaterialModuleColor color={materials.treeTopA.emissive} />
            <MaterialModuleNormal />
            <MaterialModuleFlowMap />
            <MaterialModuleWind />
          </GBufferMaterial>
        </mesh>
        <mesh geometry={nodes.Cube121_3.geometry}>
          <GBufferMaterial>
            <MaterialModuleColor color={materials.treeTopB.emissive} />
            <MaterialModuleNormal />
            <MaterialModuleFlowMap />
            <MaterialModuleWind />
          </GBufferMaterial>
        </mesh>
        <mesh geometry={nodes.Cube121_4.geometry}>
          <GBufferMaterial>
            <MaterialModuleColor color={materials.treeTopD.emissive} />
            <MaterialModuleNormal />
            <MaterialModuleFlowMap />
            <MaterialModuleWind />
          </GBufferMaterial>
        </mesh>
        {!postOutline && (
          <mesh geometry={nodes.Cube121_5.geometry}>
            <GBufferMaterial>
              <MaterialModuleColor color={materials.GreenOutline.emissive} />
              <MaterialModuleNormal />
              <MaterialModuleFlowMap />
              <MaterialModuleWind />
            </GBufferMaterial>
          </mesh>
        )}
      </group>
    </group>
  );
}
