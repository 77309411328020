import { urls } from '@/config/assets';
import { PerspectiveCamera, OrbitControls } from '@react-three/drei';
// import { OrbitControls } from 'three-stdlib';
import { useGLTF as useGLTFDrei } from '@react-three/drei';
import { damp, dampC } from 'maath/easing';

export function IceCube({ postOutline, ...props }) {
  const { scene, nodes, materials } = useAsset(urls.m_cube);
  const t_cube = useAsset(urls.t_cube);

  const { lightConfig } = useContext(IceLightingContext);

  const refBrightness = useRef(null);
  const refFlowColor = useRef(null);

  const smoothTime = 1;
  useFrame((state, delta) => {
    if (!lightConfig) return;
    damp(
      refBrightness.current,
      'amount',
      lightConfig.icecube.brightness * 1.3,
      smoothTime,
      delta
    );

    dampC(
      refFlowColor.current.color,
      lightConfig.icecube.flow.color,
      smoothTime,
      delta
    );
  });

  return (
    <group
      {...props}
      dispose={null}
    >
      <group
        position={[-0.201, 0.479, 0.444]}
        rotation={[-0.33, 0.075, 0]}
      >
        <mesh
          geometry={nodes.Cube017.geometry}
          material={materials['icecub.glb']}
        >
          <GBufferMaterial side={DoubleSide}>
            <MaterialModuleNormal />
            <MaterialModuleMap map={t_cube} />
            <MaterialModuleFresnel
              color={new Color(0x81c7ff).lerp(new Color(0xffffff), 0.6)}
            />
            <MaterialModuleBrightness ref={refBrightness} />
            <MaterialModuleFlowMap />
            <MaterialModuleFlowMapColor
              ref={refFlowColor}
              blend="+"
              color={new Color(0x81c7ff).lerp(new Color(0x000000), 0.6)}
            />
          </GBufferMaterial>
        </mesh>

        {!postOutline && (
          <mesh geometry={nodes.Cube017_1.geometry}>
            <GBufferMaterial side={FrontSide}>
              <MaterialModuleColor color={materials['Material.004'].emissive} />
            </GBufferMaterial>
          </mesh>
        )}
      </group>
    </group>
  );
}
