import { create } from 'zustand';
import Env from '@/helpers/Env';
import AudioService from '@/services/AudioService';
import { getUrlBoolean } from '@/helpers/UrlParam';

export const useAppStore = create((set) => ({
  // ****************************************
  // LOADED
  // ****************************************
  loaded: false,
  setLoaded: () =>
    set((state) => {
      return { loaded: true };
    }),

  loadProgress: 0,
  setLoadProgress: (val) =>
    set((state) => {
      return { loadProgress: val };
    }),
  showLoadScreen: true,
  setShowLoadScreen: (val) =>
    set((state) => {
      return { showLoadScreen: val };
    }),

  // ****************************************
  // CAN START
  // ****************************************
  canStart: !Env.wechat,
  setCanStart: (val) =>
    set((state) => {
      return { canStart: true };
    }),

  // ****************************************
  // AUDIO UNLOCKED
  // ****************************************
  audioUnlocked: false,
  setAudioUnlocked: (val) =>
    set((state) => {
      return { audioUnlocked: true };
    }),

  // ****************************************
  // MUTED
  // ****************************************
  muted: false,
  setMuted: (val) =>
    set((state) => {
      if (val) {
        AudioService.mute();
      } else {
        AudioService.unmute();
      }
      return { muted: val };
    }),
  toggleMuted: () =>
    set((state) => {
      let val = !state.muted;
      if (val) {
        AudioService.mute();
      } else {
        AudioService.unmute();
      }
      return { muted: val };
    }),

  // ****************************************
  // MENU
  // ****************************************
  showMenu: false,
  setShowMenu: (val) =>
    set((state) => {
      return { showMenu: val };
    }),
  toggleShowMenu: () =>
    set((state) => {
      let show = !state.showMenu;
      console.log('toggleShowMenu', show);
      return { showMenu: show };
    }),

  // bwMultiplier: getUrlBoolean('colorize') ? 0 : 1,
  bwMultiplier: 0,
  setBwMultiplier: (val) =>
    set((state) => {
      return { bwMultiplier: val };
    }),

  // ****************************************
  // SUBSCRIBE
  // ****************************************
  showSubscribe: false,
  setShowSubscribe: (val) =>
    set((state) => {
      return { showSubscribe: val };
    }),

  // // ****************************************
  // // MOUSE
  // // ****************************************
  // mouse: {
  //   clientX: undefined,
  //   clientY: undefined,
  //   x: undefined, // normalized
  //   y: undefined, // normalized
  //   isDown: false,
  // },
  // setMouse: (val) =>
  //   set((state) => {
  //     return { mouse: { ...state.mouse, ...val } };
  //   }),
}));
