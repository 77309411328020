import { MittProvider } from '@/contexts/mitt';
import { getUrlBoolean } from '@/helpers/UrlParam';
import { ui } from '@/tunnels';
import { Leva } from 'leva';
import './App.sass';

import { ColorManagement } from 'three';
ColorManagement.enabled = true;

export function App() {
  // *********************************************
  // RENDER
  // *********************************************
  return (
    <div
      id="app"
      className="fixed top-0 left-0 w-full h-full"
    >
      <Leva hidden={!getUrlBoolean('leva')} />
      <MittProvider>
        <Webgl />
        <ui.Out />
        <TheGuidelines />

        {/* <img
          className="artboard"
          src="/assets/images/artboard.jpg"
        /> */}

        <Home />
      </MittProvider>
    </div>
  );
}
