import { useRef } from 'react';

import Env from '@/helpers/Env';
import { mouse } from '@/stores/mouse';
import { damp, damp3, dampE, exp } from 'maath/easing';
import { useAppStore } from '@/stores/app';
import { clamp, map } from '@/helpers/MathUtils';
import { PinchGesture } from '@use-gesture/vanilla';
import { throttle } from 'lodash';
import gsap from 'gsap';

export const ZoomStepControls = ({
  children,

  steps = [-1, 0, 1],
  pinchAmount = 0.5,

  ...props
}) => {
  const { gl, size } = useThree();
  const refRoot = useRef();
  const zoom = useRef(0);
  const isAnimating = useRef(false);

  const zoomOut = useCallback(async () => {
    if (isAnimating.current) return;

    let currIdx = steps.indexOf(zoom.current);
    if (currIdx == steps.length - 1) return;
    isAnimating.current = true;
    zoom.current = steps[currIdx + 1];

    await gsap
      .to(refRoot.current.position, {
        z: zoom.current,
        duration: 0.8,
        ease: 'power1.inOut',
      })
      .then();

    isAnimating.current = false;
  });

  const zoomIn = useCallback(async () => {
    if (isAnimating.current) return;

    let currIdx = steps.indexOf(zoom.current);
    if (currIdx == 0) return;
    isAnimating.current = true;
    zoom.current = steps[currIdx - 1];

    await gsap
      .to(refRoot.current.position, {
        z: zoom.current,
        duration: 0.8,
        ease: 'power1.inOut',
      })
      .then();
    isAnimating.current = false;
  });

  // MOUSE WHEEL
  useEffect(() => {
    const updateScroll = (evt) => {
      if (evt.deltaY > 0) {
        zoomOut();
      } else if (evt.deltaY < 0) {
        zoomIn();
      }
    };
    window.addEventListener('wheel', updateScroll);

    return () => {
      window.removeEventListener('wheel', updateScroll);
    };
  }, [zoomOut, zoomIn]);

  // PINCH GESTURE
  let lastPinchScale = useRef(1);
  useEffect(() => {
    const gesture = new PinchGesture(gl.domElement, (state) => {
      let pinchScale = state.offset[0];
      let diff = pinchScale - lastPinchScale.current;
      if (diff > 0) {
        zoomIn();
      } else if (diff < 0) {
        zoomOut();
      }
      lastPinchScale.current = pinchScale;
    });

    const prevent = (e) => e.preventDefault();
    document.addEventListener('gesturestart', prevent);
    document.addEventListener('gesturechange', prevent);

    return () => {
      gesture.destroy();
      document.removeEventListener('gesturestart', prevent);
      document.removeEventListener('gesturechange', prevent);
    };
  }, [gl]);

  // WRAPPER
  return (
    <group
      ref={refRoot}
      {...props}
    >
      {children}
    </group>
  );
};
