import { urls } from '@/config/assets';
import { PerspectiveCamera, OrbitControls } from '@react-three/drei';
// import { OrbitControls } from 'three-stdlib';
import { useGLTF as useGLTFDrei } from '@react-three/drei';
import { damp, dampC } from 'maath/easing';

export function IceGround(props) {
  const { scene, nodes, materials } = useAsset(urls.m_ground);
  // const t_ring = useAsset(urls.t_ring);
  // const t_ring_color = useAsset(urls.t_ring_color);
  // const t_ring_normal = useAsset(urls.t_ring_normal);

  const { lightConfig } = useContext(IceLightingContext);

  const refGradient = useRef(null);
  const refFlowColor = useRef(null);

  const color1 = useMemo(() => new Color(), []);
  const color2 = useMemo(() => new Color(), []);
  const color3 = useMemo(() => new Color(), []);
  const colorFlow = useMemo(() => new Color(), []);

  const smoothTime = 2;
  useFrame((state, delta) => {
    if (!lightConfig) {
      // console.log('no light config');
      return;
    }
    // console.log(lightConfig.id, lightConfig.color1);
    dampC(
      refGradient.current.color1,
      lightConfig.text.gradient.color1,
      smoothTime,
      delta
    );
    dampC(
      refGradient.current.color2,
      lightConfig.text.gradient.color2,
      smoothTime,
      delta
    );
    dampC(
      refGradient.current.color3,
      lightConfig.text.gradient.color3,
      smoothTime,
      delta
    );
    dampC(
      refFlowColor.current.color,
      lightConfig.text.flow.color,
      smoothTime,
      delta
    );
  });

  return (
    <group
      {...props}
      dispose={null}
    >
      <group
        position={[-0.205, -0.003, 0.102]}
        scale={1.901}
      >
        <mesh
          name="text"
          geometry={nodes.Circle003.geometry}
          material={materials.text}
        >
          <GBufferMaterial>
            <MaterialModuleNormal />
            {/* <MaterialModuleColor color={materials.text.emissive} /> */}
            <MaterialModuleAnimatedGradient
              ref={refGradient}
              color1={color1}
              color2={color2}
              color3={color3}
            />
            <MaterialModuleFlowMap />
            <MaterialModuleFlowMapColor
              ref={refFlowColor}
              blend="+"
              color={colorFlow}
            />
          </GBufferMaterial>
        </mesh>

        <mesh
          name="ground"
          geometry={nodes.Circle003_1.geometry}
          material={materials.outterRing}
        >
          <GBufferMaterial>
            <MaterialModuleNormal />
            <MaterialModuleColor color={materials.outterRing.emissive} />
          </GBufferMaterial>
        </mesh>
      </group>

      <mesh
        geometry={nodes.bigGround.geometry}
        material={materials.base}
        position={[-0.831, -0.058, -4.211]}
        scale={40.122}
      >
        <GBufferMaterial>
          <MaterialModuleNormal />
          <MaterialModuleColor color={materials.base.emissive} />
        </GBufferMaterial>
      </mesh>

      {/* <group position={[-0.939, -23.07, -2.011]}>
        <mesh
          geometry={nodes.Landscape002_1.geometry}
          material={materials.outterRing}
        >
          <GBufferMaterial>
            <MaterialModuleNormal />
            <MaterialModuleColor color={materials.outterRing.emissive} />
          </GBufferMaterial>
        </mesh>
        <mesh
          geometry={nodes.Landscape002_2.geometry}
          material={materials.GreenOutline}
        >
          <GBufferMaterial>
            <MaterialModuleNormal />
            <MaterialModuleColor color={materials.GreenOutline.emissive} />
          </GBufferMaterial>
        </mesh>
      </group> */}
    </group>
  );
}
