import { urls } from '@/config/assets';
import { PerspectiveCamera, OrbitControls, Billboard } from '@react-three/drei';
// import { OrbitControls } from 'three-stdlib';
import { useGLTF as useGLTFDrei } from '@react-three/drei';

export function IceChara(props) {
  return (
    <group
      {...props}
      dispose={null}
      position={[0, 0.2, 0.5]}
    >
      <group
        scale={0.4}
        position={[-1.8, 0.01, 0.35]}
        rotation={[0, 0, 0]}
      >
        <Billboard>
          <IceSprite id="pop" />
        </Billboard>
      </group>

      <group
        scale={0.4}
        position={[-1.55, 0.01, 0.8]}
        rotation={[0, 0, 0]}
      >
        <Billboard>
          <IceSprite id="wif" />
        </Billboard>
      </group>

      <group
        scale={0.45}
        position={[-1.17, 0.01, 1]}
        rotation={[0, 0, 0]}
      >
        <Billboard>
          <IceSprite id="mew" />
        </Billboard>
      </group>

      <group
        scale={0.45}
        position={[-0.65, 0.01, 1.35]}
        rotation={[0, 0, 0]}
      >
        <Billboard>
          <IceSprite id="pepe" />
        </Billboard>
      </group>

      <group
        scale={0.5}
        position={[-0.13, 0.01, 1.45]}
        rotation={[0, 0, 0]}
      >
        <Billboard>
          <IceSprite id="shib" />
        </Billboard>
      </group>

      <group
        scale={0.4}
        position={[0.35, 0.01, 1.45]}
        rotation={[0, 0, 0]}
      >
        <Billboard>
          <IceSprite id="doge" />
        </Billboard>
      </group>

      <group
        scale={0.44}
        position={[0.75, 0.01, 1.2]}
        rotation={[0, 0, 0]}
      >
        <Billboard>
          <IceSprite id="bome" />
        </Billboard>
      </group>

      <group
        scale={0.5}
        position={[1.2, 0.01, 0.7]}
        rotation={[0, 0, 0]}
      >
        <Billboard>
          <IceSprite id="slerf" />
        </Billboard>
      </group>
    </group>
  );
}
