import { useLoadingStore } from '@/stores/loading';

export const Loading = ({ ...props }) => {
  const addLoadGroup = useLoadingStore((state) => state.addLoadGroup);
  const setLoadGroupProgress = useLoadingStore(
    (state) => state.setLoadGroupProgress
  );
  const setLoadGroupCompleted = useLoadingStore(
    (state) => state.setLoadGroupCompleted
  );

  // useEffect(() => {
  //   addLoadGroup('three');
  //   return () => {
  //     setLoadGroupProgress('three', 1);
  //     setLoadGroupCompleted('three');
  //   };
  // }, []);

  return <></>;
};
